import PauseCircle from './svg/pause-circle';
import PlayCircle from './svg/play-circle';
import SkipForward from './svg/skip-forward';
import SkipBack from './svg/skip-back';
import Volume from './svg/volume';
import RepeatList from './svg/repeat-list';
import List from './svg/list';
import File from './svg/file';
import Next from './svg/next';
import Pause from './svg/pause';
import Play from './svg/play';
import Prev from './svg/prev';
import RepeatOnce from './svg/repeat-once';
import Switch from './svg/switch';
import VolumeLite from './svg/volume-lite';
import VolumeMute from './svg/volume-mute';
import Loader from './svg/loader';
import Stop from './svg/stop';
import Chevrons from './svg/chevrons';

export const SvgPauseCircle = PauseCircle;
export const SvgPlayCircle = PlayCircle;
export const SvgSkipForward = SkipForward;
export const SvgSkipBack = SkipBack;
export const SvgVolume = Volume;
export const SvgRepeatList = RepeatList;
export const SvgList = List;
export const SvgFile = File;
export const SvgNext = Next;
export const SvgPause = Pause;
export const SvgPlay = Play;
export const SvgPrev = Prev;
export const SvgRepeatOnce = RepeatOnce;
export const SvgSwitch = Switch;
export const SvgVolumeLite = VolumeLite;
export const SvgVolumeMute = VolumeMute;
export const SvgLoader = Loader;
export const SvgStop = Stop;
export const SvgChevrons = Chevrons;
